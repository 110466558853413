// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bs_kC";
export var caseStudyBackground__lineColor = "bs_kz";
export var caseStudyHead = "bs_kt";
export var caseStudyHead__imageWrapper = "bs_kv";
export var caseStudyProjectsSection = "bs_kD";
export var caseStudyQuote__bgLight = "bs_kK";
export var caseStudyQuote__bgRing = "bs_kx";
export var caseStudyVideo__ring = "bs_kN";
export var caseStudy__bgDark = "bs_ks";
export var caseStudy__bgLight = "bs_kr";